<template>
    <div class="album-details-skeleton-loader">
        <div class="skeleton-content">
            <div class="skeleton-cover">
                <v-skeleton-loader type="image" class="skeleton-album-image"></v-skeleton-loader>
            </div>

            <div class="skeleton-info">
                <div class="skeleton-header">
                    <!-- <v-skeleton-loader type="text" class="skeleton-date"></v-skeleton-loader> -->
                    <v-skeleton-loader type="text" class="skeleton-title"></v-skeleton-loader>
                    <div class="skeleton-artist-label">
                        <v-skeleton-loader type="text" class="skeleton-artist"></v-skeleton-loader>
                        <v-skeleton-loader type="text" class="skeleton-label"></v-skeleton-loader>
                    </div>
                </div>

                <div class="skeleton-tags">
                    <v-skeleton-loader 
                        v-for="n in 6" 
                        :key="n" 
                        type="chip" 
                        class="skeleton-tag"
                    ></v-skeleton-loader>
                </div>

                <!-- <div class="skeleton-actions">
                    <v-skeleton-loader type="button" class="skeleton-button"></v-skeleton-loader>
                </div> -->

                <div class="skeleton-description">
                    <v-skeleton-loader type="text" v-for="n in 3" :key="`desc-${n}`" class="skeleton-desc-line"></v-skeleton-loader>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "albumDetailsSkeletonLoader"
}
</script>

<style lang="scss" scoped>
.album-details-skeleton-loader {
    position: relative;
    z-index: 1;
    width: 100%;

    .skeleton-content {
        display: flex;
        gap: 20px;
        padding: 24px 0;
        margin-top: -4px;

        @media (max-width: 767px) {
            flex-direction: column;
            align-items: center;
        }
    }

    .skeleton-cover {
        flex-shrink: 0;
        width: 310px;
        height: 310px;

        .skeleton-album-image {
            width: 100%;
            height: 100%;
            border-radius: 12px;
            
            ::v-deep .v-skeleton-loader__image {
                height: 100%;
                border-radius: 12px;
            }
        }
    }

    .skeleton-info {
        flex: 1;
        max-width: 700px;
    }

    .skeleton-header {
         margin-bottom: 0px;

        // .skeleton-date {
        //     width: 120px;
        //     margin-bottom: 8px;
            
        //     ::v-deep .v-skeleton-loader__text {
        //         height: 16px;
        //     }
        // }

        .skeleton-title {
            width: 80%;
            margin-bottom: 4px;
            
            ::v-deep .v-skeleton-loader__text {
                height: 32px;
            }
        }

        .skeleton-artist-label {
            display: flex;
            gap: 24px;
            
            .skeleton-artist, .skeleton-label {
                width: 200px;
                
                ::v-deep .v-skeleton-loader__text {
                    height: 20px;
                }
            }
        }
    }

    .skeleton-tags {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
        margin: 8px 0 20px;
        
        .skeleton-tag {
            width: auto;
            
            ::v-deep .v-skeleton-loader__chip {
                min-width: 60px;
                height: 20px;
                border-radius: 12px;
                background: rgba(255, 255, 255, 0.08);

                &:nth-child(1) { width: 70px; }
                &:nth-child(2) { width: 55px; }
                &:nth-child(3) { width: 85px; }
                &:nth-child(4) { width: 65px; }
                &:nth-child(5) { width: 75px; }
                &:nth-child(6) { width: 60px; }
            }
        }

        @media (max-width: 767px) {
            justify-content: flex-start;
            
            .skeleton-tag {
                ::v-deep .v-skeleton-loader__chip {
                    min-width: 50px;
                }
            }
        }
    }

    .skeleton-actions {
        margin-bottom: 32px;

        ::v-deep .v-skeleton-loader__button {
            width: 180px;
            height: 48px;
            border-radius: 12px;
        }
    }

    .skeleton-description {
        .skeleton-desc-line {
            margin-bottom: 8px;
            
            ::v-deep .v-skeleton-loader__text {
                height: 16px;
            }

            &:nth-child(1) {
                width: 100%;
            }
            &:nth-child(2) {
                width: 90%;
            }
            &:nth-child(3) {
                width: 75%;
            }
        }
    }
}
</style> 